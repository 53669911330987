import {Behaviour, behaviours} from '../../../global/scripts/behaviours/behaviour';
import Swiper from 'swiper';
import  {EffectFade, Navigation, Autoplay, Pagination} from 'swiper/modules';

const blockTextTeasers: Behaviour = (function () {

    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>('.blockTextTeasers');

        if (blocks.length === 0) {
            return;
        }
        blocks.forEach(block => init(block, context));
    };

    const init = function (block: HTMLElement, context: HTMLElement) {
        const baseClass = 'blockTextTeasers';
        const sliderElement = block.querySelector<HTMLElement>(`.${baseClass}__teasers`);
        const wrapperClass = `${baseClass}__items`;
        const slideClass = `${baseClass}__item`;
        let renderBullet = null;

        const navClass = "iconHolder";
        const buttonNext = block?.querySelector<HTMLElement>(
            `.${navClass}--next`
        );
        const buttonPrev = block?.querySelector<HTMLElement>(
            `.${navClass}--prev`
        );

        if (!sliderElement) {
            return false;
        }

        renderBullet = function (index: number, className: string) {
            return '<span class="' + className + '">' + (index + 1) + '</span>';
        }

        const swiper = new Swiper(sliderElement, {
            modules: [Navigation, Pagination],
            wrapperClass: wrapperClass,
            slideClass: slideClass,
            slideActiveClass: `${baseClass}__item--active`,
            slideVisibleClass: `${baseClass}__item--visible`,
            speed: 400,
            watchSlidesProgress: true,
            slidesPerView: 1.1,
            spaceBetween: 17,
            pagination: {
                el: `.swiperPagination`,
                type: 'bullets',
                bulletClass: `bullet`,
                bulletActiveClass: `bullet--active`,
                clickable: true,
            },
            navigation: {
                nextEl: buttonNext,
                prevEl: buttonPrev,
                disabledClass: `${navClass}--disabled`,
                hiddenClass: `${navClass}--hidden`,
                lockClass: `${navClass}--lock`,
            },
            breakpoints: {
                480: {
                    slidesPerView: 1.050,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 32,
                },
            },
        });

    };


    return {
        attach: attach
    }

})();

behaviours.addBehaviour(blockTextTeasers);
