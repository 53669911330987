import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import {Behaviour, behaviours} from '../../../global/scripts/behaviours/behaviour';

const mobileDrawerBehaviour: Behaviour = (function () {
    const baseClass = 'mobileDrawer';
    let drawer: HTMLElement | null = null;

    const attach = function (context: HTMLElement) {
        drawer = context.querySelector(`.${baseClass}`) as HTMLElement;

        const toggleButtons = context.querySelectorAll('.mobileMenuTrigger') as NodeListOf<HTMLElement>;

        // No menu found, so exit
        if (!drawer) {
            return false;
        }

        // Behaviour already attached, so exit
        if (drawer.classList.contains(`${baseClass}--behaviourAttached`)) {
            return false;
        }

        drawer.classList.add(`${baseClass}--behaviourAttached`);

        toggleButtons.forEach((button) => {
            if (drawer) {
                init(drawer, button);
            }
        });
    };

    const open = function () {
        if (!drawer) {
            return false;
        }

        const menu = drawer.querySelector('.mobileMenu') as HTMLElement;
        drawer.classList.add(`${baseClass}--open`);
        document.documentElement.classList.add('mobileDrawerIsOpen');
        disableBodyScroll(menu);
        document.dispatchEvent(new Event('mobileDrawerOpened'));
    };

    const close = function () {
        if (!drawer) {
            return false;
        }

        const menu = drawer.querySelector('.mobileMenu') as HTMLElement;
        drawer.classList.remove(`${baseClass}--open`);
        document.documentElement.classList.remove('mobileDrawerIsOpen');
        enableBodyScroll(menu);
        document.dispatchEvent(new Event('mobileDrawerClosed'));
    };

    const init = function (drawer: HTMLElement, toggleButton: HTMLElement) {
        toggleButton.addEventListener('click', function () {
            const isOpen = drawer.classList.contains(`${baseClass}--open`);
            toggleButton.classList.toggle('hamburger--active', !isOpen);
            if (isOpen) {
                close();
            } else {
                open();
            }
        });
    };

    return {
        attach: attach,
        close: close,
        open: open,
    };
})();

behaviours.addBehaviour(mobileDrawerBehaviour);
