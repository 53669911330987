import {Behaviour, behaviours} from '../../../global/scripts/behaviours/behaviour';
import Swiper from 'swiper';
import  {Navigation, Pagination} from 'swiper/modules';

const blockTextNumberTeasers: Behaviour = (function () {

    const attach = function (context: HTMLElement) {
        const blocks = context.querySelectorAll<HTMLElement>('.blockTextNumberTeasers');

        if (blocks.length === 0) {
            return;
        }

        blocks.forEach(block => init(block, context));
    };

    const init = function (block: HTMLElement, context: HTMLElement) {
        const baseClass = 'blockTextNumberTeasers';
        const sliderElement = block.querySelector<HTMLElement>(`.${baseClass}__teasers`);
        const wrapperClass = `${baseClass}__items`;
        const slideClass = `${baseClass}__item`;

        const navClass = "iconHolder";
        const buttonNext = block?.querySelector<HTMLElement>(
            `.${navClass}--next`
        );
        const buttonPrev = block?.querySelector<HTMLElement>(
            `.${navClass}--prev`
        );

        if (!sliderElement) {
            return false;
        }

        const swiper = new Swiper(sliderElement, {
            modules: [Navigation, Pagination],
            wrapperClass: wrapperClass,
            slideClass: slideClass,
            slideActiveClass: `${baseClass}__item--active`,
            slideVisibleClass: `${baseClass}__item--visible`,
            speed: 400,
            watchSlidesProgress: true,
            slidesPerView: 1.07,
            spaceBetween: 17,
            navigation: {
                nextEl: buttonNext,
                prevEl: buttonPrev,
                disabledClass: `${navClass}--disabled`,
                hiddenClass: `${navClass}--hidden`,
                lockClass: `${navClass}--lock`,
            },
            pagination: {
                el: `.swiperPagination`,
                type: 'bullets',
                bulletClass: `bullet`,
                bulletActiveClass: `bullet--active`,
                clickable: true,
            },
            breakpoints: {
                480: {
                    slidesPerView: 1.030,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 29,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                }
            },
        });
    };

    return {
        attach: attach
    }

})();

behaviours.addBehaviour(blockTextNumberTeasers);
