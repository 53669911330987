// typical import
import gsap from "gsap";

// get other plugins:
import ScrollTrigger from "gsap/ScrollTrigger";
import {behaviours} from './behaviours/behaviour';

gsap.registerPlugin(ScrollTrigger);

behaviours.addBehaviour(
    (() => {

        const attach = function () {
            document.documentElement.classList.add('animation-ready');

            if (document.body.classList.contains('wp-admin')) {
                return;
            }
            const animationParents = document.querySelectorAll<HTMLElement>('.animationGroup');

            animationParents.forEach((animationParent) => {

                // Scroll Animations can be expanded in the future

                // Fade Up
                animationParent.querySelectorAll<HTMLElement>('.animationFadeUp').forEach((animation) => {
                    const delayAttr = animation.getAttribute('data-animation-delay') ?? 0;

                    ScrollTrigger.defaults({
                        // once: true
                        start: "top 100%",
                    });

                    gsap.timeline({
                        paused: true,
                        scrollTrigger: {
                            trigger: animationParent
                        }
                    })
                    .fromTo(animation, {
                        y: 20,
                        opacity: 0
                    }, {
                        y: 0,
                        opacity: 1,
                        delay: '0.' + delayAttr,
                        duration: 0.2
                    });
                });
            });
        };

        return {
            attach: attach,
        };
    })(),
);
