import {enableBodyScroll, disableBodyScroll} from 'body-scroll-lock';
import {Behaviour, behaviours} from '../../../global/scripts/behaviours/behaviour';

const modal: Behaviour = (function () {

    const attach = function (context: HTMLElement) {
        const documentClasslist = document.documentElement.classList
        const cssClass = 'modalsInitiated';
        if (documentClasslist.contains(cssClass)) {
            return;
        }
        documentClasslist.add(cssClass)
        init(document.documentElement);


    };

    const init = function (element: HTMLElement) {
        const openModalLinks = element.querySelectorAll('.behaviourOpenModal');
        const closeModalLinks = element.querySelectorAll('.behaviourCloseModal');

        openModalLinks.forEach((link) => {
            link.addEventListener('click', (event) => {
                event.preventDefault();

                const modalSelector = link.getAttribute('data-modal-selector')
                if (modalSelector) {
                    const openedModal =  openModal(modalSelector);

                    if(openedModal) {
                        document.dispatchEvent(new CustomEvent('modalOpened', {detail: {
                            modal: openedModal,
                            link: link
                        }}));
                    }
                }
            });

        })

        closeModalLinks.forEach((link) => {
            link.addEventListener('click', (event) => {
                event.preventDefault();
                const modal = link.closest('.modal');

                if (!modal) {
                    return;
                }

                resetForm(modal as HTMLElement);
                modal.classList.remove('modal--active');
                enableBodyScroll(modal);

            });
        })

        window.onkeydown = function( event ) {
            if (event.key === 'Escape' ) {
                const openModal = document.querySelector('.modal--active');

                if(!openModal) {
                    return;
                }
                if (openModal) {
                    event.preventDefault();
                    resetForm(openModal as HTMLElement);
                    openModal.classList.remove('modal--active');
                    enableBodyScroll(openModal);

                }
            }
        };
    };

    const openModal = function (targetSelector:string) {
        const modal = document.querySelector(targetSelector);
        if (modal) {

            modal.classList.add('modal--active');
            disableBodyScroll(modal);

            return modal;
        }
        return null;
    }

    const resetForm = function (modal:HTMLElement) {
        const formId =  modal.querySelector<HTMLElement>('.gform_confirmation_wrapper ')?.getAttribute('id')?.replace('gform_confirmation_wrapper_', '');

        if (!formId) {
            return;
        }

        const selector: string = `gwrf_${formId}`;
        const thisWindow = window as any;

        const gwrf = thisWindow[selector];

        if( typeof gwrf != 'undefined' ) {
            gwrf.reloadForm();

            modal.classList.remove('modal--confirmation');

            const modalTitle:HTMLElement | any  = modal.querySelector('.modal__title');
            const modalText = modal.querySelector<HTMLDivElement>('.modal__text ');
            const modalCancelBtn = modal.querySelector<HTMLDivElement>('.modal__cancel ');

            if(!modalTitle || !modalText || !modalCancelBtn) {
                return
            }

            modalTitle.style.display = 'block';
            modalText.style.display = 'block';
            modalCancelBtn.style.display = 'block';

        }
    }

    return {
        attach: attach
    }
})();

behaviours.addBehaviour(modal);
