import {behaviours} from './behaviours/behaviour';

behaviours.addBehaviour(
    (() => {
        const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

        const attach = function () {
            document.querySelectorAll('div[data-background]').forEach((block) => {
                const background = block.getAttribute('data-background');
                const nextBlock = block.nextElementSibling;
                const previousBlock = block.previousElementSibling;

                if (!previousBlock?.hasAttribute('data-background' || !background)) {
                    return;
                }

                previousBlock.setAttribute('data-next-block', block.classList[0]);
                block.setAttribute('data-previous-block', previousBlock.classList[0]);

                if (!nextBlock?.hasAttribute('data-background') || !previousBlock) {
                    return;
                }

                if (block.getAttribute('data-pull-background') === 'top') {
                    if (previousBlock && previousBlock.hasAttribute('data-background')) {
                        previousBlock.setAttribute('data-background-pulled', 'bottom');
                    }

                }
                const nextBackground = nextBlock.getAttribute('data-background') ?? '';

                if (block && block.hasAttribute('data-background') && block.getAttribute('data-pull-background') == 'bottom') {
                    // @ts-ignore
                    block.classList.add(`${block.classList[0]}--backgroundBottom${capitalizeFirstLetter(nextBlock.getAttribute('data-background'))}`);
                } else if (block && block.hasAttribute('data-background') && block.getAttribute('data-pull-background') == 'top') {
                    // @ts-ignore
                    block.classList.add(`${block.classList[0]}--backgroundTop${capitalizeFirstLetter(previousBlock.getAttribute('data-background'))}`);
                }

                block.setAttribute('data-next-background', nextBackground);
                if (background) {
                    previousBlock.setAttribute('data-next-background', background);
                }
            });
        };

        return {
            attach: attach,
        };
    })(),
);
