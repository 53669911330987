import {hasTouch} from "./hasTouch";

const setVars = () => {
    const vh = window.innerHeight * 0.01;
    const vw = document.body.clientWidth * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    document.documentElement.style.setProperty('--vw', `${vw}px`);
    document.documentElement.classList.toggle('deviceHasTouch', hasTouch());
    document.documentElement.classList.toggle('deviceNoTouch', !hasTouch());
};

const setInitVars = () => {
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.documentElement.style.setProperty('--scrollbarWidth', `${scrollbarWidth}px`);
};

window.addEventListener('load', setInitVars);
window.addEventListener('load', setVars);
window.addEventListener('resize', setVars);
