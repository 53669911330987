import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import {Behaviour, behaviours} from '../../../global/scripts/behaviours/behaviour';

/**
 * Scroll behaviour
 */
const imageViewer: () => Behaviour = () => {
    const attach = (context: HTMLElement) => {
        Fancybox.bind('[data-fancybox]', {});
    };

    return {
        attach,
    };
};

behaviours.addBehaviour(imageViewer());
