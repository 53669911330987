import {behaviours} from './behaviour';

if (typeof wp !== 'undefined' && typeof wp.data !== 'undefined') {
    wp.data.subscribe(function () {
        const blocks = document.documentElement.querySelectorAll('.acf-block-preview') as NodeListOf<HTMLElement>;
        blocks.forEach(function (block) {
            if (!block.classList.contains('viv-loaded')) {
                behaviours.attachBehaviours(block);
                block.classList.add('viv-loaded');
            }
        });
    });
}
